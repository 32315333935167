<template>
  <article class="customers">
    <div class="large flex-row c-margin">
      <main class="flex-col">
        <p class="text--black" style="padding-bottom: 2em">Nedan är ett urval av tidigare och nuvarande kunder med genomförda uppdrag.</p>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Kävlinge kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av park och gatumark</p></li>
            <li><p class="text--black no-margin">asfaltbeläggning</p></li>
            <li><p class="text--black no-margin">vinterväghållning</p></li>
            <li><p class="text--black no-margin">förstärkningsarbeten av enskilda vägar</p></li>
            <li><p class="text--black no-margin">vägmarkeringar.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Lomma kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av gatubelysning</p></li>
            <li><p class="text--black no-margin">beläggningsinventering.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Burlövs kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av park och gatuskötsel för hela kommunen (2019)</p></li>
            <li><p class="text--black no-margin">ramavtal för mark och anläggningsentreprenader (2022).</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Helsingborgs stad</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">skötseln för utemiljön inom skolor, förskolor och äldreboenden (2021).</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Yrkesakademin</p><p class="text--black">YH-utbildning (från 2016) för arbetsledare inom grönyteskötsel och förvaltning med kursansvar för</p>
          <ul>
            <li><p class="text--black no-margin">entreprenadjuridik</p></li>
            <li><p class="text--black no-margin">ekonomi och kalkylering</p></li>
            <li><p class="text--black no-margin">Projekt Management.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Akademi Båstad</p><p class="text--black">YH-utbildning (från 2016) för arbetsledare och trädgårdsanläggare med kursansvar för</p>
          <ul>
            <li><p class="text--black no-margin">entreprenadjuridik</p></li>
            <li><p class="text--black no-margin">upphandling och kalkylering</p></li>
            <li><p class="text--black no-margin">handledning av examensarbeten.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Sveriges lantbruksuniversitet, SLU, Alnarp</p><p class="text--black">Föreläsning och handledning inom landskapsarkitekt- och landskapsingenjörsprogrammet (från 2018).</p>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Svenska Kyrkan Helsingborg, Kyrkogårdsförvaltningen</p><p class="text--black">Upphandling och projektledning av renoverings- och nybyggnadsprojekt av kyrkogårdar (från 2018).</p>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">BRF Ryttmästaren i Helsingborg</p><p class="text--black">Projektering och upphandling av ombyggnad av yttre miljö (2017).</p>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">BRF Runan i Helsingborg</p><p class="text--black">Förslag och projektering av ombyggnad av yttre miljö (2016).</p>
        </div>
        <div>
          <p class="text--black text-bold no-margin">Maria Park samfällighetsförening</p><p class="text--black">Framtagning av skötselplan och upphandling samt löpande besiktning av skötselarbeten inklusive upprättande av beskärningsplan.</p>
        </div>
      </main>
      <div class="images-list flex-col">
        <img :src="img1" alt="">
        <img :src="img2" alt="">
        <img :src="img3" alt="">
        <img :src="img4" alt="">
      </div>
    </div>

    <div class="small flex-col c-margin">
      <main>
        <p class="text--black" style="padding-bottom: 30px">Nedan är ett urval av tidigare och nuvarande kunder med genomförda uppdrag.</p>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Kävlinge kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av park och gatumark</p></li>
            <li><p class="text--black no-margin">asfaltbeläggning</p></li>
            <li><p class="text--black no-margin">vinterväghållning</p></li>
            <li><p class="text--black no-margin">förstärkningsarbeten av enskilda vägar</p></li>
            <li><p class="text--black no-margin">vägmarkeringar.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Lomma kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av gatubelysning</p></li>
            <li><p class="text--black no-margin">beläggningsinventering.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Burlövs kommun</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">drift och underhåll av park och gatuskötsel för hela kommunen (2019)</p></li>
            <li><p class="text--black no-margin">ramavtal för mark och anläggningsentreprenader (2022).</p></li>
          </ul>
        </div>
        <div style="display: flex">
          <img :src="img1" alt="">
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Helsingborgs stad</p><p class="text--black">Framtagning av förfrågningsunderlag och upphandling av</p>
          <ul>
            <li><p class="text--black no-margin">skötseln för utemiljön inom skolor, förskolor och äldreboenden (2021).</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Yrkesakademin</p><p class="text--black">YH-utbildning (från 2016) för arbetsledare inom grönyteskötsel och förvaltning med kursansvar för</p>
          <ul>
            <li><p class="text--black no-margin">entreprenadjuridik</p></li>
            <li><p class="text--black no-margin">ekonomi och kalkylering</p></li>
            <li><p class="text--black no-margin">Projekt Management.</p></li>
          </ul>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Akademi Båstad</p><p class="text--black">YH-utbildning (från 2016) för arbetsledare och trädgårdsanläggare med kursansvar för</p>
          <ul>
            <li><p class="text--black no-margin">entreprenadjuridik</p></li>
            <li><p class="text--black no-margin">upphandling och kalkylering</p></li>
            <li><p class="text--black no-margin">handledning av examensarbeten.</p></li>
          </ul>
        </div>
        <div style="display: flex">
          <img :src="img2" alt="">
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Sveriges lantbruksuniversitet, SLU, Alnarp</p><p class="text--black">Föreläsning och handledning inom landskapsarkitekt- och landskapsingenjörsprogrammet (från 2018).</p>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">Svenska Kyrkan Helsingborg, Kyrkogårdsförvaltningen</p><p class="text--black">Upphandling och projektledning av renoverings- och nybyggnadsprojekt av kyrkogårdar (från 2018).</p>
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">BRF Ryttmästaren i Helsingborg</p><p class="text--black">Projektering och upphandling av ombyggnad av yttre miljö (2017).</p>
        </div>
        <div style="display: flex">
          <img :src="img3" alt="">
        </div>
        <div style="padding-bottom: 2em">
          <p class="text--black text-bold no-margin">BRF Runan i Helsingborg</p><p class="text--black">Förslag och projektering av ombyggnad av yttre miljö (2016).</p>
        </div>
        <div>
          <p class="text--black text-bold no-margin">Maria Park samfällighetsförening</p><p class="text--black">Framtagning av skötselplan och upphandling samt löpande besiktning av skötselarbeten inklusive upprättande av beskärningsplan.</p>
        </div>
      </main>
    </div>
  </article>
</template>

<script>
import img1 from '../assets/f1.png'
import img2 from '../assets/f2.png'
import img3 from '../assets/f3.png'
import img4 from '../assets/f4.png'

export default {
  setup() {
    return {
      img1,
      img2,
      img3,
      img4,
    }
  }
}
</script>

<style scoped>

.customers {
  padding: 4em 0;
}

.customers > .large {
  display: flex;
  justify-content: space-between;
}
.customers > .small {
  display: none;
}

.customers >>> main {
  justify-content: space-between;
  margin-right: 2em;
}

ul, ul li {
  margin: 5px;
}

.images-list {
  justify-content: space-between;
}

.images-list > img {
  min-width: 0;
  width: 20vw;
  min-width: 260px;
  /* max-width: 350px; */
  margin: 1em 0;
  box-shadow: 0px 4px 10px #00000034;
}

.small >>> img {
  min-width: 0;
  width: 60vw;
  margin: 40px auto;
  box-shadow: 0px 4px 10px #00000034;
}

@media screen and (max-width: 750px) {
  
  .customers > .large {
    display: none;
  }
  .customers > .small {
    display: flex;
  }

  .customers >>> main {
    margin-right: 0px;
  }

}

</style>
<template>
  <div class="nav" :class="{'nav-fixed': scrollPosition > 50}">
    <img :src="logo" class="logo"/>
    <div class="buttons" :class="{'active': burgerActive}">
      <a @click="toggleBurger(0, false)">Hem</a>
      <a @click="toggleBurger(1, false)">Företaget</a>
      <a @click="toggleBurger(2, false)">Tjänster</a>
      <a @click="toggleBurger(3, false)">Kunder & Uppdrag</a>
      <a @click="toggleBurger(4, false)">Kontakt</a>
    </div>
    <div class="spacer"></div>
    <div class="burger" @click="toggleBurger(-1, !burgerActive)">
      <div class="line1" :class="{'active': burgerActive}"></div>
      <div class="line2" :class="{'active': burgerActive}"></div>
      <div class="line3" :class="{'active': burgerActive}"></div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/rk.svg'

export default {
  data() {
    return {
      scrollPosition: null,
      burgerActive: false,
    }
  },
  methods: {
    updateScroll(event) {
      // console.log('äwda', document.querySelector('#wrapper').scrollTop)
      this.scrollPosition = window.scrollY
    },
    toggleBurger(index, toggle) {
      this.burgerActive = toggle
      if(index >= 0) this.$emit('scrollTo', index)
    }
  },
  mounted() {
    // document.querySelector('body').addEventListener('scroll', this.updateScroll)
    window.addEventListener('scroll', this.updateScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.updateScroll)
  },
  setup() {
    return {
      logo
    };
  }
}
</script>

<style>
.logo {
  width: 10em;
  left: 0;
  box-sizing: border-box;
}
.spacer {
  width: 150px;
}
.nav {
  user-select: none;
  width: 100%;
  height: 6em;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10vw;
  -ms-transition: background-color 400ms, border-bottom 400ms ease;
  -webkit-transition: background-color 400ms, border-bottom 400ms ease;
  transition: background-color 400ms, border-bottom 400ms ease;
  border-bottom: 0.2em solid transparent;
  z-index: 1000;
  position: fixed;
  background-color: transparent;
}
.nav-fixed {
  background-color: rgb(255, 255, 255);
  border-bottom: 0.2em solid #799B25;
}
.buttons {
  display: inline-block;
}
.buttons a {
  margin: 1em 2vw;
  display: inline-block;
  color: #1C1C1C;
  font-style: normal;
  font-size: 1.1em;
  font-weight: lighter;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 0.15em solid transparent;
}
.buttons a:hover {
  color: #799B25;
  border-bottom: 0.15em solid #799B25;
}
.burger {
  display: none;
}

/* Medium */
@media screen and (max-width: 1250px) {
  .spacer {
    display: none;
  }
}

@media screen and (max-width: 1000px) {
  .buttons a {
    margin: 1em 1.2vw;
    font-size: 0.9em;
  }
}

/* Small */
@media screen and (max-width: 750px) {
  .buttons {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 0;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.562);
    right: 0;
    transform: translateX(100%);
    top: 0;
    padding-top: 80px;
    padding-right: 10vw;
    padding-left: 10vw;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    /* border-bottom: 2px solid #7a9b25;
    border-left: 2px solid #7a9b25; */
    text-align: right;
    -webkit-transition: transform 400ms ease;
    transition: transform 400ms ease;
  }
  .buttons.active {
    transform: translateX(0);
  }
  .buttons a {
    margin: 10px 0;
    color: white;
  }
  .buttons a:hover {
    color: white;
  }
  .burger {
    cursor: pointer;
    display: inline-block;
    z-index: 1;
  }
  .line1, .line2, .line3 {
    background-color: #799B25;
    width: 30px;
    height: 5px;
    margin: 5px 0;
    border-radius: 10px;
    -webkit-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  .line1.active {
    background-color: white;
    transform: translate(0, 10px) rotate(135deg);
  }
  .line2.active {
    background-color: white;
    opacity: 0;
  }
  .line3.active {
    background-color: white;
    transform: translate(0, -10px) rotate(-135deg);
  }
}
</style>
<template>
  <article class="bio" style="padding-bottom: 4em">
    <div class="large-bio flex-row flex-jcc">
      <img :src="pontus" class="bio-img" alt="">
      <img :src="tina" class="bio-img" alt="">
    </div>
    <div class="bio-section">
      <div class="pontus-section c-margin" style="padding-bottom: 2em">
        <img :src="pontus" class="small-bio bio-img" alt="">
        <p class="text--black">
          <span class="text-bold">Pontus Runeke</span>
          <br><br>
          Pontus är utbildad landskapsarkitekt (examensår 1996) med lång och bred erfarenhet inom grönytebranschen. Han har genom åren arbetat med många olika projekt både som beställare på Helsingborgs Stad och som entreprenör på Väla Mark & Trädgård AB.
        </p>
        <ul>
          <li>
            <p>
              <span class="text-bold">Helsingborgs Stad, Stadsbyggnadsförvaltningen</span> (1998 - 2011) - anställning som landskapsarkitekt 1998 - 2004, enhetschef 2004 - 2007 och avdelningschef 2007 - 2011 på Drift- och underhållsavdelningen med cirka 15 medarbetare. Pontus hade utöver personalansvar även ansvar för drift och underhåll av gatumark, parkmark, skog och natur och stränder.
            </p>
          </li>
          <li>
            <p>
              <span class="text-bold">Väla Mark & Trädgård AB</span> (2012 - 2016) - Anställning som regionchef med cirka 60 anställda. Väla Mark & Trädgård AB är ett privat företag som ingår i HEDE Danmark från 2012 med verksamhet inom skötsel och anläggning av grönytor och övrig utemiljö.
            </p>
          </li>
          <li>
            <p>
              <span class="text-bold">Runeke Konsult AB</span> (från 2011) - VD och konsultuppdrag som landskapsarkitekt. Sedan 2016 drivs företaget på heltid.
            </p>
          </li>
        </ul>
      </div>
      <div class="tina-section c-margin">
        <img :src="tina" class="small-bio bio-img" alt="">
        <p class="text--black">
          <span class="text-bold">Tina Runeke</span>
          <br><br>
          Tina är specialist i allmänmedicin sedan 2009 (examensår läkarlinjen 1998) och har utöver nuvarande anställning som allmänläkare på Läkargruppen Munka Ljungby (sedan 2011) även uppdrag inom Allmänläkarkonsult (AKO) Skåne, en del av region Skåne. I det uppdraget ingår bland annat att författa medicinska riktlinjer åt primärvården i Skåne samt nationellt inom Nationellt kliniskt kunskapsstöd (NKK) på uppdrag av Sveriges kommuner och regioner (SKR).
        </p>
      </div>
    </div>
  </article>
</template>

<script>
import pontus from '@/assets/Pontus.png'
import tina from '@/assets/Tina.png'

export default {
  setup() {
    return {
      pontus,
      tina
    }
  }
}
</script>

<style scoped>

.large-bio {
    display: flex;
  }
.small-bio {
  display: none;
}

.bio-img {
  max-width: 100%;
  width: auto;
  height: 25vw;
  object-fit: cover;
  margin: 0 1em;
  box-shadow: 0px 4px 10px #00000034;
}
.bio-section {
  margin-top: 4em;
}

@media screen and (max-width: 750px) {
  .bio-img {
    margin: 30px auto;
    height: 70vw;
    box-sizing: border-box;
  }
  .large-bio {
    display: none;
  }
  .small-bio {
    display: block;
    
  }
  .bio-section {
    margin: 0;
  }
}
</style>
<template>
  <div style="position: relative; transform-style: initial;">
    <!-- <Navbar class="nav"/> -->
    <img src="@/assets/forest.jpg" class="bg-image">
    <div class="cite">
      <div class="frosted-glass" style="padding: 4em 0">
        <h1 style="margin-bottom: 0.6em" class="c-margin text-light text-center">Välkommen till Runeke Konsult</h1>
        <h4 class="c-margin text-light text-italic text-center">Ett konsultföretag inom utbildning, upphandling och projektledning av grönyteskötsel och utemiljö</h4>
      </div>
    </div>
  </div>
</template>

<script>
import '../styles/main.css'
import Navbar from './Navbar.vue'

export default {
  components: { Navbar },
}
</script>

<style>
.bg-container {
  background: url('~@/assets/forest.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  justify-content: center;
  display: flex;

}
.bg-container .nav{
  position: fixed;
}

.bg-image {
  height: 100vh;
  max-height: 100%;
  width: 100%;
  padding: 0;
  display: block;
  margin: 0 auto;
  object-fit: cover;
  
}

.cite {
  position: absolute;
  display: flex;
  width: 100%;
  transform: translate(-50%, 0);
  left: 50%;
  bottom: min(calc(20% - .5px), 300px);
  
}
/* .cite p {
  color: white;
  font-style: italic;
  font-size: calc(12px + 1.5vw);
  margin: 10px 0;
} */

h1 {
  margin: 0;
}

</style>
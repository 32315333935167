<template>
  <article class="about flex-row flex-aic c-margin">
    <img :src="leaf" class="leaf"/>
    <main>
      <p class="text--black"><span class="text-bold">Runeke Konsult AB</span> är ett konsultföretag som bildades år 2011 och som främst bedriver:</p>
      <ul>
        <li><p class="text--black">Uppdrag inom projektledning och konsultstöd vid upphandling och genomförande av anläggnings-, drift- och underhållsentreprenader.</p></li>
        <li><p class="text--black">Utbildningar inom bland annat grönyteskötsel, arbetsledning, kalkylering, ekonomi och entreprenadjuridik.</p></li>
      </ul>
    </main>
  </article>
</template>

<script>
import leaf from '@/assets/leaf.svg'
import SegmentTitle from '@/components/SegmentTitle.vue'
import Bio from '@/components/Bio.vue'

export default {
  data() {
    return {
      showMore: false,
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    window.addEventListener('resize', () => this.windowWidth = window.innerWidth)
  },
  setup() {
    return {
      leaf,
    }
  },
  components: {
    SegmentTitle,
    Bio,
  }
}
</script>

<style scoped>

article.about {
  padding: 4em 0;
}

.leaf {
  margin-bottom: 0px;
  margin-right: 5%;
  width: 20%;
}

ul li {
  margin: 30px 0;
}

@media screen and (max-width: 750px) {
  article.about {
    padding-top: 60px;
    padding-bottom: 10px;
    flex-direction: column;
    align-items: center;
  }
  .leaf {
    margin-bottom: 50px;
    width: 30%;
  }
}
</style>
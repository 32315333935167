<template>
  <article class="services">
    <main class="c-margin">
      <div style="padding-bottom: 2em">
        <p class="text--black text-bold">Utbildning och kurser</p>
        <ul>
          <li><p class="text--black">Ekonomi och kalkylering.</p></li>
          <li><p class="text--black">Upphandling och entreprenadjuridik.</p></li>
          <li><p class="text--black">Ledarskap, arbetsledning och kommunikation.</p></li>
        </ul>
      </div>
      <div style="padding-bottom: 2em">
        <p class="text--black text-bold">Upphandling och upphandlingsstöd</p>
        <ul>
          <li><p class="text--black">Upprättande av förfrågningsunderlag för upphandling av skötsel och drift- och underhållsentreprenader av utemiljö samt mark- och anläggning med mera.</p></li>
          <li><p class="text--black">Stöd och medverkan genom hela upphandlingsprocessen, inklusive bland annat annonsering, anbudsutvärdering, tilldelning och upprättande av avtal/kontrakt.</p></li>
        </ul>
      </div>
      <div>
        <p class="text--black text-bold">Projektledning</p>
        <ul>
          <li><p class="text--black">Uppstart av nya entreprenader.</p></li>
          <li><p class="text--black">Kvalitetsuppföljning av pågående och avslutade entreprenader.</p></li>
          <li><p class="text--black">Slutbesiktning av drift- och underhållsentreprenader.</p></li>
          <li><p class="text--black">Byggledning, byggmöten, ekonomiuppföljning och löpande administration.</p></li>
        </ul>
      </div>
        
    </main>
  </article>
</template>

<script>
import SegmentTitle from '@/components/SegmentTitle.vue'
import img1 from '@/assets/forest2.jpg'

export default {
  
  components: {
    SegmentTitle,
  },
  setup() {
    return {
      img1,
    }
  }
}
</script>

<style scoped>

.services {
  padding: 4em 0;
}

</style>
<template>
  <footer class="footer flex-row">
    <div class="logo-container">
      <img :src="logo" alt="">
    </div>
    <div class="links flex-col flex-jcc">
      <p style="margin-top: 0"><span class="text-bold" style="font-size: 1.2em">Runeke Konsult</span></p>
      <div class="flex-col">
        <a @click="() => this.$emit('scrollTo', 0)">Hem</a>
        <a @click="() => this.$emit('scrollTo', 1)">Företaget</a>
        <a @click="() => this.$emit('scrollTo', 2)">Tjänster</a>
        <a @click="() => this.$emit('scrollTo', 3)">Kunder & Uppdrag</a>
        <a @click="() => this.$emit('scrollTo', 4)">Kontakt</a>
      </div>
    </div>
  </footer>
</template>

<script>
import logo from '@/assets/rk_white.svg'

export default {
  setup() {
    return {
      logo,
    }
  }
}
</script>

<style scoped>

.footer {
  width: 100%; 
  height: 16em;
  background-color: #799B25;
  user-select: none;
}

.logo-container {
  background-color: rgba(255, 255, 255, 0.192);
  display: flex;
  justify-content: center;
}

.logo-container > img {
  margin: 0 10vw;
  width: 10em;
}

.links {
  margin-left: 1em;
}

.links >>> a {
  color: white;
  /* font-size: 1.1em; */
  font-weight: lighter;
  cursor: pointer;
  margin: 0.1em 0;
}

.links >>> a:hover {
  color:rgba(255, 255, 255, 0.377);
}

@media screen and (max-width: 750px) {
  .logo-container > img {
    margin: 0 6vw;
  }
}

</style>
<template>
  <metainfo></metainfo>
  <Navbar @scroll-to="click"/>
  <div style="position: relative"  ref="scroll0">
    <HomeSegment />
  </div>

  <div style="position: relative">
    <div class="scroll-target" ref="scroll1" />
    <About />
  </div>
  <Bio />

  <div style="position: relative">
    <div class="scroll-target" ref="scroll2" />
    <ParallaxJS :img="img1" titleText="Tjänster" />
  </div>
  <Services />

  <div style="position: relative">
    <div class="scroll-target" ref="scroll3" />
    <ParallaxJS :img="img2" title-text="Kunder & Uppdrag"/>
  </div>
  <Customers />

  <div style="position: relative">
    <div class="scroll-target" ref="scroll4" />
    <ParallaxJS :img="img3" title-text="Kontakt"/>
  </div>
  <Contact />

  <Footer @scroll-to="click"/>

</template>

<script>
import Wrapper from './components/Wrapper.vue'
import HomeSegment from './components/HomeSegment.vue'
import About from './components/About.vue'
import Services from './components/Services.vue'
import Customers from './components/Customers.vue'
import Bio from './components/Bio.vue'
import Contact from './components/Contact.vue'
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import ParallaxJS from './components/ParallaxJS.vue'
import img1 from './assets/DSC_7073.png'
import img2 from './assets/DSC_7097.png'
import img3 from './assets/DSC_7085.png'

import { useMeta } from "vue-meta";

export default {
  name: 'App',
  components: {
    Wrapper,
    HomeSegment, 
    About,
    Services,
    Customers,
    Bio,
    Contact,
    Navbar,
    Footer,
    ParallaxJS,
  },
  setup() {
    useMeta({
      title: 'Runeke Konsult AB',
      htmlAttrs: {
        lang: 'se'
      },
      meta: [
        { name: 'description', content:  'Runeke Konsult AB är ett konsultföretag som bildades år 2011 och som främst bedriver: Uppdrag inom projektledning och konsultstöd vid upphandling och genomförande av anläggnings-, drift- och underhållsentreprenader. Utbildningar inom bland annat grönyteskötsel, arbetsledning, kalkylering, ekonomi och entreprenadjuridik.'},
        { property: 'og:title', content: "Runeke Konsult - Ett konsultföretag inom utbildning, upphandling och projektledning av grönyteskötsel och utemiljö"},
        { property: 'og:site_name', content: 'Runeke Konsult'},
        { property: 'og:type', content: 'website'},
      ]
    })

    return {
      img1,
      img2,
      img3,
    }
  },
  methods: {
    click(index) {
      this.$refs['scroll' + index].scrollIntoView({behavior: "smooth"})
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;1,300&display=swap');

.spacer2 {
  height: 500px;
}
.parallax-margin {
  margin: 0 0;
}
.scroll-target {
  position: absolute;
  top: -70px;
}

</style>

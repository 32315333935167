<template>
  <article class="contact c-margin">
    <main class="flex-col" style="justify-content: space-between; margin-right: 8em">
      <div style="padding-bottom: 2em">
        <p class="text--black no-margin">
          <span class="text-bold">Runeke Konsult AB</span><br>
          Augustigatan 1<br>
          256 61 Helsingborg
        </p>
      </div>
      <div style="padding-bottom: 2em">
        <p class="text--black no-margin">
          <span class="text-bold">Pontus Runeke</span><br>
          VD, landskapsarkitekt<br>
          Telefon: <a href="tel:+46707157707">0707-157707</a><br>
          E-post: <a href="mailto:pontus@runekekonsult.se">pontus@runekekonsult.se</a>
        </p>
      </div>
      <div style="padding-bottom: 2em">
        <p class="text--black no-margin">
          <span class="text-bold">Tina Runeke</span><br>
          Delägare, specialist i allmänmedicin<br>
          Telefon: <a href="tel:+46707154440">0707-154440</a><br>
          E-post: <a  href="mailto:tina.runeke@live.se">tina.runeke@live.se</a>
        </p>
      </div>
      <div>
        <p class="text--black no-margin">
          Organisationsnummer: 556858 - 9500<br>
          Innehar F-skattsedel
        </p>
      </div>
    </main>
    <img :src="img" alt="">
  </article>
</template>

<script>
import img from '../assets/f5.png'

export default {
  setup() {
    return {
      img,
    }
  }
}
</script>

<style scoped>

.contact {
  display: flex;
  flex-direction: row;
  padding: 4em 0;
  justify-content: space-between;
}

.contact >>> p {
  width: max-content;
}

.contact > img {
  min-width: 0;
  object-fit: cover;
  /* max-width: 40vw; */
}

@media screen and (max-width: 750px) {
  .contact {
    flex-direction: column;
  }

  .contact > img {
    min-width: 0;
    object-fit: cover;
    margin-top: 4em;
    /* max-width: 40vw; */
  }
}

</style>
<template>
  <div class="parallax-container" ref="cont">
    <img :src="img" :style="{ top: `${offset}vh` }" ref="parallax1" alt="">
    <div class="frosted-glass">
      <h4 style="margin: 1.3em auto; font-weight: bold; font-size: max(1.8vw, 16px);" class="c-margin text-light text-center text-italic">{{ titleText }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: '',
    titleText: String,
  },
  data() {
    return {
      offset: null
    }
  },
  methods: {
    parallax(event) {
      if(window.innerWidth > 750) {
        const bounds = this.$refs.cont.getBoundingClientRect()
        var y = -(bounds.top / window.innerHeight) * 100 * 0.2 - (300 / (window.innerHeight / 100))
        this.offset = y
        // console.log(window.scrollY, bounds.top / window.innerHeight, y)
      } else {
        this.offset = -50
      }
      // console.log(this.$refs.parallax1)
    }
  },
  mounted() {
    window.addEventListener('scroll', this.parallax)
  },
  deactivated() {
    window.removeEventListener('scoll', this.parallax)
  },
  unmounted() {
    window.removeEventListener('scoll', this.parallax)
  },

}
</script>

<style>

.parallax-container {
  position: relative;
  overflow: hidden;
  height: max(20vw, 170px);
}

.parallax-container > .frosted-glass {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.parallax-container > img {
  position: absolute;
  top: -50%;
  bottom: 0;
  left: 0;
  right: 0;
  /* width: 100%; */
  min-width: 100%;
  height: 100vh;
  max-height: 2400px;
  z-index: -1;
  object-fit: cover;
}

@media screen and (max-width: 750px) {

  .parallax-container {
    position: flex;
    overflow: hidden;
    height: max(20vw, 170px);
    justify-content: center;
    align-items: center;
  }

  .parallax-container > img {
    position: absolute;
    top: -50%;

    /* width: 100%; */

    width: 100%;
    z-index: -1;
  }

}

</style>
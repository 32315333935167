
import { createApp } from 'vue'
import App from './App.vue'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAUkc5oWQzg22EZRMBcM0oRBiJ7jwcELxg",
  authDomain: "runeke-konsult-ab.firebaseapp.com",
  projectId: "runeke-konsult-ab",
  storageBucket: "runeke-konsult-ab.appspot.com",
  messagingSenderId: "28256685768",
  appId: "1:28256685768:web:8de7ca2b5aa54a839b9ffd",
  measurementId: "G-FRE3DDWB0R"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);


const app = createApp(App)

import { createMetaManager, plugin as metaPlugin } from 'vue-meta'

const metaManager = createMetaManager()
app.use(metaManager)


app.mount('#app')


<template>
  <div class="wrapper" id="wrapper">
    <slot></slot>
  </div>
  
</template>

<script>
export default {
 
}
</script>

<style>
.wrapper {
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: unset;
  perspective: 300px;
}
</style>
<template>
  <div class="title">
    <div class="ruler">
      <span><slot /></span>
    </div>
  </div>
  
</template>

<script>
export default {

}
</script>

<style>
.ruler {
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  text-align: center;
}
.ruler::before, .ruler::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.212);
}
.ruler span {
  font-size: 40px;
  color: #799B25;
  font-style: italic;
  padding: 0 30px;
}

@media screen and (max-width: 1250px) {
  .ruler span {
    font-size: 30px;
    padding: 0 20px;
  }
}
</style>